<div class="container">
  <a #download download="life-calendar.jpg" hidden></a>
  <a #downloadPrintable download="life-calendar-imprimable.jpg" hidden></a>
  <img #canvasImage [hidden]="!show" (click)="downloadCalendar()"/>
  <canvas #canvasElement hidden></canvas>
  <canvas #printElement hidden></canvas>
  <div class="content">
    <h2>Ceci sert à te rappeler</h2>
    <p>Que chaque semaine de ta vie est importante. Qu'il vaut mieux vivre maintenant.</p>
    <p>Que tu es libre de donner du sens à chaque instant... Ou pas.</p>
    <p>Que si tu n'as pas les meilleures cartes en main, il n'appartient qu'à toi de les jouer au mieux, de les changer, ou de continuer à piocher...</p>
  </div>
  <button mat-raised-button color="primary" (click)="downloadPrintableCalendar()" *ngIf="show">Version imprimable</button>
  <!-- Your share button code -->
  <div class="fb-share-button"
       data-href="https://lifecalendar.hypnocurieux.com"
       data-layout="button"
       data-size="large">
  </div>
</div>

