import {Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BirthDate} from '../birthdate/birthdate.component';
import {TextFieldBrowser} from '../text-field-browser';

@Component({
  selector: 'app-lifespan-field',
  templateUrl: './lifespan-field.component.html',
  styleUrls: ['./lifespan-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LifespanFieldComponent),
    multi: true
  }],
})
export class LifespanFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  size = 3;
  @Input()
  errors: {};
  @Output()
  changed: EventEmitter<any> = new EventEmitter();
  @Output()
  goToPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  goToNext: EventEmitter<any> = new EventEmitter<any>();
  lifespan: string;
  @ViewChild('el')
  textFieldElement: ElementRef<HTMLElement>;
  textFieldBrowser: TextFieldBrowser;

  onChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.lifespan;
  }

  set value(value: string) {
    this.lifespan = value;
    this.textFieldBrowser.onChange(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor() { }

  ngOnInit(): void {
    this.textFieldBrowser = new TextFieldBrowser(this.size);
    this.textFieldBrowser.subscribe(this.goToPrevious, this.goToNext);
  }

  ngOnDestroy() {
    this.textFieldBrowser.unsubscribe();
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get hasError() {
    if (!this.errors) {
      return false;
    }
    return Object.keys(this.errors).length > 0;
  }

  focus() {
    if (this.textFieldElement) {
      this.textFieldElement.nativeElement.focus();
    }
  }

  keyDown(event: KeyboardEvent) {
    this.textFieldBrowser.onKeyDown(event);
  }
}
