import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BirthDate, BirthdateComponent} from './birthdate/birthdate.component';
import {FieldComponent} from './birthdate/field/field.component';
import {LifespanFieldComponent} from './lifespan-field/lifespan-field.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'decide';
  pageForm: FormGroup;
  submitted = false;

  @ViewChild('birthdateInput', {static: false})
  birthdateComponent: BirthdateComponent;
  @ViewChild('lifespanInput', {static: false})
  lifespanComponent: LifespanFieldComponent;

  constructor(private formBuilder: FormBuilder) {
    this.pageForm = this.formBuilder.group({
      birthdate: [null, Validators.compose([Validators.required, this.validBirthdate()])],
      lifespan: [80, Validators.compose([Validators.required, Validators.min(60), Validators.max(100)])],
      elderAge: [65, Validators.compose([Validators.required, Validators.min(50), Validators.max(100)])],
    }, {validators: Validators.compose([this.elderAgeAboveAge(), this.lifespanAboveAge(), this.lifespanAboveElderAge()])});
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.birthdateComponent.focus();
  }

  get birthdate() {
    return this.pageForm.get('birthdate');
  }

  get lifespan() {
    return this.pageForm.get('lifespan');
  }

  get elderAge() {
    return this.pageForm.get('elderage');
  }

  validBirthdate(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      const birthdate: BirthDate = control.value;
      if (!birthdate) {
        return null;
      }
      const errors = {};
      birthdate.validate(errors);
      return errors;
    };
  }

  lifespanAboveAge(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const birthdate: BirthDate = group.get('birthdate').value;
      const lifespan: number =  group.get('lifespan').value;
      if (!birthdate) {
        return null;
      }
      if (lifespan < (new Date().getFullYear() - birthdate.year)) {
        return {'lifespan.below.age': {value: lifespan}};
      }
      return null;
    };
  }


  elderAgeAboveAge(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const birthdate: BirthDate = group.get('birthdate').value;
      const elderAge: number =  group.get('elderAge').value;
      if (!birthdate) {
        return null;
      }
      if (elderAge < (new Date().getFullYear() - birthdate.year)) {
        return {'elderAge.below.age': {value: elderAge}};
      }
      return null;
    };
  }

  lifespanAboveElderAge(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const elderAge: number = group.get('elderAge').value;
      const lifespan: number =  group.get('lifespan').value;
      if (elderAge && lifespan < elderAge) {
        return {'lifespan.below.elderAge': {value: lifespan}};
      }
      return null;
    };
  }



  onSubmit() {
    this.submitted = true;
  }

  goTo(value: string) {
    switch (value) {
      case 'birthdate':
        this.birthdateComponent.focus();
        break;
      case 'lifespan':
        this.lifespanComponent.focus();
        break;
      case 'submit':
        break;
    }
  }

}
