import {AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BirthDate} from '../birthdate/birthdate.component';
import {min} from 'rxjs/operators';

@Component({
  selector: 'app-lifecalendar',
  templateUrl: './lifecalendar.component.html',
  styleUrls: ['./lifecalendar.component.scss']
})
export class LifecalendarComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  show: boolean;
  @Input()
  birthDate: BirthDate;
  @Input()
  lifespan: number;
  @ViewChild('canvasElement')
  canvas: ElementRef;
  @ViewChild('printElement')
  canvasForPrint: ElementRef;
  @ViewChild('canvasImage')
  canvasImg: ElementRef;
  @ViewChild('download')
  download: ElementRef;
  @ViewChild('downloadPrintable')
  downloadPrintable: ElementRef;
  shown: boolean;
  downloaded: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!this.shown && this.show && this.birthDate) {
      this.showCalendar();
    }
  }


  getElapsedWeeks() {
    return this.getWeekCount(BirthDate.toDate(this.birthDate), new Date());
  }

  getWeekCount(d1: Date, d2: Date) {
    const millisBetween = d2.getTime() - d1.getTime();
    return Math.round(millisBetween / (7 * 24 * 60 * 60 * 1000));
  }

  getTotalWeeks() {
    return this.lifespan * 52;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.show && this.canvas && this.birthDate) {
      this.showCalendar();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.show && this.canvas && this.birthDate) {
      this.showCalendar();
    }
  }

  private showCalendar() {
    const weeksInOneYear = 52;
    const weeksSinceBirth = this.getElapsedWeeks();
    const weeksOfBirthYearBeforeBirth = this.getWeekCount(new Date(this.birthDate.year, 1, 1), BirthDate.toDate(this.birthDate));
    const weeksBeforeRetirement = (this.lifespan - 15) * weeksInOneYear + weeksOfBirthYearBeforeBirth + 1;
    const weeksAfterRetirement = this.lifespan * weeksInOneYear - weeksBeforeRetirement;
    const context = this.canvas.nativeElement.getContext('2d');
    if (this.shown) {
      context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }
    const canvasWidth = 1920;
    const canvasHeight = 1080;
    const scale = 1;
    context.canvas.width = canvasWidth * scale;
    context.canvas.height = canvasHeight * scale;
    context.scale(scale, scale);
    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvasWidth, canvasHeight);
    context.fillStyle = '#38bbc9';
    context.font = 'bold 40px Arial';
    context.textAlign = 'center';
    context.fillText('Life Calendar', canvasWidth / 2, 74, canvasWidth);
    context.font = 'bold 20px Arial';
    context.fillText('1 case = 1 semaine de ma vie', canvasWidth / 2, 100, canvasWidth);
    context.font = 'bold 20px Arial';
    context.fillText('Tu n\'arrives pas à te décider ?', canvasWidth / 2, canvasHeight - 36, canvasWidth);
    context.font = 'bold 18px Arial';
    context.fillStyle = '#000000';
    context.fillText('lifecalendar.hypnocurieux.com', canvasWidth / 2, canvasHeight - 10, canvasWidth);
    const padding = 82;
    const yearTextWidth = 50;
    const headerHeight = 30;
    const spaceBetweenCells = 1;
    const stepX = Math.floor((canvasWidth - 2 * yearTextWidth - 2 * padding) / (2 * weeksInOneYear) - spaceBetweenCells);
    const stepY = Math.floor(((canvasHeight - headerHeight - 2 * padding) / (this.lifespan / 2) - spaceBetweenCells));
    // console.log('Calcul taille idéale de 2 x padding + 2 x yearTextWidth');
    // console.log(canvasWidth - ((stepX + spaceBetweenCells) * 52 * 2));
    let xCoord = padding + yearTextWidth;
    let yCoord = padding + headerHeight;
    let totalWeeksSinceBeginning = 0;
    let weeksRemaining = weeksInOneYear * this.lifespan;
    for (let y = 0; y < this.lifespan; y++) {
      if (y % 2 === 0) {
        xCoord = padding + yearTextWidth;
        yCoord += stepY + spaceBetweenCells;
        if (y % 10 === 0) {
          context.fillStyle = '#38bbc9';
          context.font = 'bold 16px Arial';
          context.textAlign = 'right';
          context.fillText(y.toString() + 'ans', padding + yearTextWidth, yCoord + stepY * 2 / 3, yearTextWidth);
        } else {
          context.font = 'bold 14px Arial';
          context.fillText(this.birthDate.year + y, padding + yearTextWidth, yCoord + stepY * 2 / 3, yearTextWidth);
        }

      } else {
        xCoord += yearTextWidth;
        context.font = 'bold 14px Arial';
        context.fillText(this.birthDate.year + y, xCoord, yCoord + stepY * 2 / 3, yearTextWidth);
      }
      for (let w = 0; w < weeksInOneYear; w++) {
        totalWeeksSinceBeginning++;
        weeksRemaining--;
        if (w === weeksOfBirthYearBeforeBirth) {
          context.fillStyle = '#38bbc9';
        } else if (y === 0 && w < weeksOfBirthYearBeforeBirth) {
          context.fillStyle = '#888';
        } else if (totalWeeksSinceBeginning < weeksOfBirthYearBeforeBirth + weeksSinceBirth) {
          context.fillStyle = '#f44336';
        } else if (totalWeeksSinceBeginning === weeksOfBirthYearBeforeBirth + weeksSinceBirth) {
          context.fillStyle = '#ff80ab';
        } else if (totalWeeksSinceBeginning < weeksBeforeRetirement) {
          context.fillStyle = '#ddd';
        } else if (totalWeeksSinceBeginning >= weeksBeforeRetirement) {
          let color = (192 - Math.floor(192 * (weeksAfterRetirement - weeksRemaining) / weeksAfterRetirement)).toString(16);
          if (color.length === 1) {
            color = '0' + color;
          }
          context.fillStyle = `#${color}${color}${color}`;
        }
        xCoord += stepX + spaceBetweenCells;
        context.fillRect(xCoord, yCoord, stepX, stepY);
      }
    }
    const image = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.canvasImg.nativeElement.src = image;
    this.download.nativeElement.setAttribute('href', image);
    if (!this.downloaded && window.innerWidth < 600) {
      this.downloadCalendar();
    }
    this.shown = true;
  }


  private makeCanvasForPrint() {
    const weeksInOneYear = 52;
    const weeksSinceBirth = this.getElapsedWeeks();
    const weeksOfBirthYearBeforeBirth = this.getWeekCount(new Date(this.birthDate.year, 1, 1), BirthDate.toDate(this.birthDate));
    const weeksBeforeRetirement = (this.lifespan - 15) * weeksInOneYear + weeksOfBirthYearBeforeBirth + 1;
    const weeksAfterRetirement = this.lifespan * weeksInOneYear - weeksBeforeRetirement;
    const context = this.canvasForPrint.nativeElement.getContext('2d');
    if (this.shown) {
      context.clearRect(0, 0, this.canvasForPrint.nativeElement.width, this.canvasForPrint.nativeElement.height);
    }
    const canvasWidth = 3840;
    const canvasHeight = 2160;
    const scale = 1;
    context.canvas.width = canvasWidth * scale;
    context.canvas.height = canvasHeight * scale;
    context.scale(scale, scale);
    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvasWidth, canvasHeight);
    context.fillStyle = '#38bbc9';
    context.font = 'bold 80px Arial';
    context.textAlign = 'center';
    context.fillText('Life Calendar', canvasWidth / 2, 120, canvasWidth);
    context.font = 'bold 40px Arial';
    context.fillText('1 case = 1 semaine de ma vie', canvasWidth / 2, 165, canvasWidth);
    context.font = 'bold 40px Arial';
    context.fillText('Imprime ton propre calendrier gratuitement sur', canvasWidth / 2, canvasHeight - 70, canvasWidth);
    context.font = 'bold 36px Arial';
    context.fillStyle = '#333333';
    context.fillText('lifecalendar.hypnocurieux.com', canvasWidth / 2, canvasHeight - 25, canvasWidth);
    const padding = 160;
    const yearTextWidth = 100;
    const headerHeight = 0;
    const spaceBetweenCells = 2;
    const stepX = Math.floor((canvasWidth - 2 * yearTextWidth - 2 * padding) / (2 * weeksInOneYear) - spaceBetweenCells);
    const stepY = Math.floor(((canvasHeight - headerHeight - 2 * padding) / (this.lifespan / 2) - spaceBetweenCells));
    // console.log('Calcul taille idéale de 2 x padding + 2 x yearTextWidth');
    // console.log(canvasWidth - ((stepX + spaceBetweenCells) * 52 * 2));
    let xCoord = padding + yearTextWidth;
    let yCoord = padding + headerHeight;
    let totalWeeksSinceBeginning = 0;
    let weeksRemaining = weeksInOneYear * this.lifespan;
    for (let y = 0; y < this.lifespan; y++) {
      if (y % 2 === 0) {
        xCoord = padding + yearTextWidth;
        yCoord += stepY + spaceBetweenCells;
        if (y % 10 === 0) {
          context.fillStyle = '#38bbc9';
          context.font = 'bold 32px Arial';
          context.textAlign = 'right';
          context.fillText(y.toString() + 'ans', padding + yearTextWidth, yCoord + stepY * 2 / 3, yearTextWidth);
        } else {
          context.fillStyle = '#333333';
          context.font = 'bold 28px Arial';
          context.fillText(this.birthDate.year + y, padding + yearTextWidth, yCoord + stepY * 2 / 3, yearTextWidth);
        }

      } else {
        xCoord += yearTextWidth;
        context.fillStyle = '#333333';
        context.font = 'bold 28px Arial';
        context.fillText(this.birthDate.year + y, xCoord + 12, yCoord + stepY * 2 / 3, yearTextWidth);
      }
      for (let w = 0; w < weeksInOneYear; w++) {
        totalWeeksSinceBeginning++;
        weeksRemaining--;
        let drawCross = false;
        if (w === weeksOfBirthYearBeforeBirth) {
          drawCross = totalWeeksSinceBeginning < weeksOfBirthYearBeforeBirth + weeksSinceBirth;
          context.fillStyle = '#38bbc9';
        } else if (y === 0 && w < weeksOfBirthYearBeforeBirth) {
          context.fillStyle = '#888';
        } else if (totalWeeksSinceBeginning < weeksOfBirthYearBeforeBirth + weeksSinceBirth) {
          drawCross = true;
          context.fillStyle = '#ddd';
        } else if (totalWeeksSinceBeginning === weeksOfBirthYearBeforeBirth + weeksSinceBirth) {
          context.fillStyle = '#ddd';
        } else if (totalWeeksSinceBeginning < weeksBeforeRetirement) {
          context.fillStyle = '#ddd';
        } else if (totalWeeksSinceBeginning >= weeksBeforeRetirement) {
          let color = (192 - Math.floor(192 * (weeksAfterRetirement - weeksRemaining) / weeksAfterRetirement)).toString(16);
          if (color.length === 1) {
            color = '0' + color;
          }
          context.fillStyle = `#${color}${color}${color}`;
        }
        xCoord += stepX + spaceBetweenCells;
        context.fillRect(xCoord, yCoord, stepX, stepY);
        if (drawCross) {
          context.fillStyle = '#333';
          context.font = 'bold 32px Arial';
          context.fillText('X', xCoord + 25, yCoord + stepY * 2 / 3 + 3, 32);
        }
      }
    }
    return this.canvasForPrint.nativeElement.toDataURL('image/jpeg');
  }

  downloadCalendar() {
    this.download.nativeElement.click();
    this.downloaded = true;
  }

  downloadPrintableCalendar() {
    const image = this.makeCanvasForPrint();
    this.downloadPrintable.nativeElement.setAttribute('href', image);
    this.downloadPrintable.nativeElement.click();
    this.downloaded = true;
  }
}
