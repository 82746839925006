<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .content {
    display: flex;
    margin: 10px auto 32px;
    padding: 0;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

</style>

<div class="content" role="main">

  <!-- Highlight Card -->
  <img src="assets/logo.png" alt="Logo Hypno-Curieux" class="logo"/>
  <h1>Tu as du mal à te décider ?</h1>
  <h3>Bienvenue sur Life Calendar</h3>

  <!-- Resources -->
  <form [formGroup]="pageForm" (ngSubmit)="onSubmit()">
    <div>
      <h2>Date de naissance*</h2>
      <app-birthdate formControlName="birthdate" (goToNext)="goTo('lifespan')" [errors]="birthdate.errors"
                     #birthdateInput></app-birthdate>
    </div>
    <div>
      <h2>Espérance de vie*</h2>
      <app-lifespan-field [errors]="lifespan.errors" (goToPrevious)="goTo('birthdate')" (goToNext)="goTo('submit')"
                          formControlName="lifespan" #lifespanInput></app-lifespan-field>
    </div>
    <div class="break"></div>
    <div class="submit">
      <button mat-raised-button color="primary" [disabled]="!pageForm.valid" type="submit">Voir mon calendrier</button>
    </div>
  </form>

  <div>
    <app-lifecalendar [birthDate]="birthdate.value" [lifespan]="lifespan.value" [show]="submitted"></app-lifecalendar>
  </div>
  <!-- Footer -->
  <footer>
    <hr/>
    <div class="container">
      <p>Ce site ne collecte aucune donnée personnelle</p>
      <p>Page développée par Loïc Ortola pour le meetup <a
        href="https://www.meetup.com/Hypno-Curieux/" target="_blank">Hypno-Curieux</a></p>
      <div>Vous souhaitez consulter ?
        <a href="http://hypnocurieux.com" target="_blank" rel="noopener">
          Prendre contact
        </a>
      </div>
    </div>
  </footer>

</div>

<router-outlet></router-outlet>
