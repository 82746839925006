import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {FieldComponent} from './field/field.component';
import {Subscription} from 'rxjs';

export class BirthDate {
  day: number;
  month: number;
  year: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }

  static toDate(d: BirthDate): Date {
    if (!d) {
      return;
    }
    return new Date(d.year, d.month, d.day);
  }

  validate(errors: {}) {
    if (!this.year || this.year && (this.year < 1900 || this.year > new Date().getFullYear())) {
      errors['yearOfBirth.invalid'] = this;
    }
    if (!this.month || this.month && (this.month < 1 || this.month > 12)) {
      errors['monthOfBirth.invalid'] = this;
    }
    const daysOfMonth = new Date(this.year, this.month, 0).getDate();
    if (!this.day || this.day < 1 || this.day > 31 || (!errors['monthOfBirth.invalid'] && this.day && this.day > daysOfMonth)) {
      errors['dayOfBirth.invalid'] = this;
    }
  }
}

@Component({
  selector: 'app-birthdate',
  templateUrl: './birthdate.component.html',
  styleUrls: ['./birthdate.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BirthdateComponent),
    multi: true
  }],
})
export class BirthdateComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  private pickerSubscription: Subscription;

  constructor() {
    const now = new Date();
    this.startDate.setFullYear(now.getFullYear() - 25);
  }

  get value() {
    return this.date;
  }

  set value(value: BirthDate) {
    this.date = value;
    this.onChange(value);
    this.onTouched();
  }

  @ViewChild('day', {static: false})
  dayComponent: FieldComponent;
  @ViewChild('month', {static: false})
  monthComponent: FieldComponent;
  @ViewChild('year', {static: false})
  yearComponent: FieldComponent;
  @ViewChild('picker')
  picker: MatDatepicker<any>;

  startDate = new Date();
  date: BirthDate;

  @Output()
  goToNext: EventEmitter<any> = new EventEmitter();
  @Output()
  goToPrevious: EventEmitter<any> = new EventEmitter();

  @Input()
  errors: {};

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // If using picker, will update inputs
    this.pickerSubscription = this.picker.closedStream.subscribe(f => {
      const date: Date = this.picker._selected;
      if (!date) {
        return;
      }
      this.date = new BirthDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      this.dayComponent.update(this.date.day.toString());
      this.monthComponent.update(this.date.month.toString());
      this.yearComponent.update(this.date.year.toString());
      this.goToNext.emit();
    });
  }

  ngOnDestroy() {
    if (this.pickerSubscription) {
      this.pickerSubscription.unsubscribe();
    }
  }

  onDayUpdated(day: string) {
    this.value = new BirthDate(this.value?.year, this.value?.month, day ? parseInt(day, 10) : 0);
  }

  onMonthUpdated(month: string) {
    this.value = new BirthDate(this.value?.year, month ? parseInt(month, 10) : 0, this.value?.day);
  }

  onYearUpdated(year: string) {
    this.value = new BirthDate(year ? parseInt(year, 10) : 0, this.value?.month, this.value?.day);
  }

  hasError(key: string) {
    const err = this.errors && this.errors.hasOwnProperty(key);
    return err;
  }

  goTo(value: string) {
    switch (value) {
      case 'day':
        this.dayComponent.focus();
        break;
      case 'month':
        this.monthComponent.focus();
        break;
      case 'year':
        this.yearComponent.focus();
        break;
      case 'previous':
        this.goToPrevious.emit();
        break;
      case 'next':
        this.goToNext.emit();
        break;
    }
  }

  focus() {
    if (this.hasError('dayOfBirth.invalid')) {
      return this.dayComponent.focus();
    }
    if (this.hasError('monthOfBirth.invalid')) {
      return this.monthComponent.focus();
    }
    if (this.dayComponent.isComplete() && this.monthComponent.isComplete()) {
      return this.yearComponent.focus();
    }
    if (this.dayComponent.isComplete()) {
      return this.monthComponent.focus();
    }
    this.dayComponent.focus();
  }
}
