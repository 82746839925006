import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TextFieldBrowser} from '../../text-field-browser';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit, OnDestroy {

  @Input()
  name: string;
  @Input()
  default: string;
  @Input()
  size = 2;
  @Output()
  changed: EventEmitter<any> = new EventEmitter();
  @Output()
  goToPrevious: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  goToNext: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  hasError: boolean;
  @ViewChild('el')
  textFieldElement: ElementRef<HTMLElement>;
  textFieldBrowser: TextFieldBrowser;
  private val: string;



  constructor() {
  }

  ngOnInit(): void {
    this.textFieldBrowser = new TextFieldBrowser(this.size);
    this.textFieldBrowser.subscribe(this.goToPrevious, this.goToNext);
  }

  ngOnDestroy() {
    this.textFieldBrowser.unsubscribe();
  }

  update(value: string) {
    this.value = value;
  }

  get value() {
    return this.val;
  }

  set value(newValue: string) {
    this.val = newValue;
    this.changed.emit(newValue);
    this.textFieldBrowser.onChange(newValue);
  }

  focus() {
    if (this.textFieldElement) {
      const inputElement = this.textFieldElement.nativeElement as HTMLInputElement;
      inputElement.select();
    }
  }

  isComplete() {
    return this.val && this.val.length === this.size;
  }

  keyDown(event: KeyboardEvent) {
    this.textFieldBrowser.onKeyDown(event);
  }
}
