<div class="container">
  <app-field #day name="Jour" (changed)="onDayUpdated($event)" (goToNext)="goTo('month')" (goToPrevious)="goTo('previous')" [hasError]="hasError('dayOfBirth.invalid')"></app-field>
  <app-field #month name="Mois" (changed)="onMonthUpdated($event)" (goToNext)="goTo('year')" (goToPrevious)="goTo('day')" [hasError]="hasError('monthOfBirth.invalid')"></app-field>
  <app-field #year name="Année" [size]="4" (changed)="onYearUpdated($event)" (goToNext)="goTo('next')" (goToPrevious)="goTo('month')" [hasError]="hasError('yearOfBirth.invalid')"></app-field>
  <div hidden>
    <input [matDatepicker]="picker" matNativeControl>
    <mat-datepicker startView="multi-year" [startAt]="startDate" touchUi #picker></mat-datepicker>
  </div>
  <a mat-raised-button (click)="picker.open()">
    <svg viewBox="0 0 24 24" width="30px" height="30px" fill="currentColor" focusable="false"
         class="mat-datepicker-toggle-default-icon ng-star-inserted">
      <path
        d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path>
    </svg>
  </a>

</div>
